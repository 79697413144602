<template>
  <div>
    <div :style="alwaysTop"></div>
  </div>
</template>

<script>

export default {
  name: 'AlwaysTop',
  props: {
    recalc: Boolean,
    height: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      pos: 0,
      initialHeight: 0,
    }
  },
  created() {
    window.addEventListener('scroll', this.handleScroll)
    this.recalcHeight()
  },
  computed: {
    alwaysTop() {
      if (this.pos > 100) {
        return {
          'height': '' + (this.pos - 90) + 'px',
        }
      } else {
        return {}
      }
    },
  },
  watch: {
    recalc: function() {
      if (this.recalc) {
        if (this.height) {
          this.recalcHeight()
        }
        this.handleScroll()
        this.$emit('done', {})
      }
    },
  },
  methods: {
    handleScroll() {
      const pos = window.scrollY
      if ((window.innerHeight === 0) || (pos < this.initialHeight)) {
        this.pos = pos
      }
    },
    recalcHeight() {
      const that = this
      setTimeout(
        function() {
          that.initialHeight = document.body.scrollHeight
        }, 100
      )
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
</style>
